<template>
  <div class="orderDetails">


    <div class="orderTime">
      <div class="orderTime-lower">
        <p>下单时间</p>
        <p>{{ data.createTime }}</p>
      </div>
      <div class="orderTime-payment" v-if="data.payTime" >
        <p>付款时间</p>
        <p>{{ data.payTime }}</p>
      </div>
    </div>

    <div class="orderState">
      当前订单状态：{{ data.status? $store.state.orderState.filter(item => { return item.dictKey == data.status })[0].dictValue : null }}
    </div>

<!--    买家信息-->
    <div class="orderDetails-info" >

      <div class="orderDetails-info-title">
        买家信息
      </div>

      <ul class="orderDetails-info-Buyers">
        <li>
          购买人：{{ data.buyPersonName }}
        </li>
        <li>
          购买人手机号： {{ data.buyPersonPhoneNo }}
        </li>
        <li v-if="data.status == 1 || data.status == 2 || data.status == 3" >
          支付方式：{{ data.transactionId ? "微信支付" : "现金支付" }}
        </li>
        <li v-if="data.transactionId">
          交易流水号：{{ data.transactionId }}
        </li>
      </ul>

    </div>

<!--    订单信息-->
    <div class="orderDetails-info">

      <div class="orderDetails-info-title">
        订单信息
      </div>

      <div class="orderDetails-info-item">
        <ul class="orderDetails-info-item-time">
          <li>
            订单编号：{{ data.orderNo }}
          </li>
          <li>
            下单时间：{{ data.createTime }}
          </li>
          <li v-if="data.payTime">
            付款时间：{{ data.payTime }}
          </li>
        </ul>

        <div class="orderDetails-info-item-table">
          <el-row>
            <el-col :span="6">
              <div class="orderDetails-info-item-table-content">
                <p>订单编号</p>
                <p>{{ data.orderNo }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="orderDetails-info-item-table-content">
                <p>商品名称</p>
                <p>{{ data.productName }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="orderDetails-info-item-table-content">
                <p>商品类别</p>
                <p>{{ data.productCategory ? $store.state.category.filter(item => { return item.dictKey == data.productCategory })[0].dictValue : null }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="orderDetails-info-item-table-content" style="border-right: 1px solid #ebebeb;" >
                <p>商品价格</p>
                <p v-if="data.productPrice != null">{{ data.productPrice != 0 ? '￥' + data.productPrice : "卡支付" }}</p>
              </div>
            </el-col>
          </el-row>
        </div>

        <template v-if=" data.orderItemList && data.orderItemList.length != 0" >
          <div class="item-list">
            <div class="item-list-title">
              <el-row>
                <el-col :span="6">
                  <div class="item-list-title-text">附加项名称</div>
                </el-col>
                <el-col :span="6">
                  <div class="item-list-title-text">适用性别</div>
                </el-col>
                <el-col :span="6">
                  <div class="item-list-title-text">附加项金额</div>
                </el-col>
                <el-col :span="6">
                  <div class="item-list-title-text" style="border-right: none" >项目类型</div>
                </el-col>
              </el-row>
            </div>
            <div class="content">
              <div class="item-list-content" v-for="(item,index) in data.orderItemList" :key="index" >
                <el-row>
                  <el-col :span="6">
                    <div class="item-list-content-text">{{ item.itemName }}</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="item-list-content-text">{{ $store.state.fitGender.filter(items => { return items.dictKey == item.fitGender })[0].dictValue }}</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="item-list-content-text">{{ '￥' + item.itemPrice }}</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="item-list-content-text" style="border-right: none" >{{ $store.state.itemType.filter(items => {return items.dictKey == item.itemType})[0].dictValue }}</div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </template>

        <ul class="orderDetails-info-item-price">
          <li v-if="data.productPrice != null">
            订单商品金额：{{ '￥' + data.productPrice }}
          </li>
          <li>
            附加项金额：{{ '￥' + data.itemPriceAmount }}
          </li>
          <li v-if="data.actualPaymentAmount != null">
            实收款：{{ '￥' + data.actualPaymentAmount }}
          </li>
        </ul>

      </div>

    </div>

<!--    预约信息-->
    <div class="orderDetails-info">

      <div class="orderDetails-info-title">
        预约信息
      </div>

      <div class="orderDetails-info-make">

        <div class="orderDetails-info-make-info">
          <el-row>
            <el-col :span="6">
              <p>预约人姓名：{{ data.name }}</p>
            </el-col>
            <el-col :span="6">
              <p>预约人性别：{{ data.gender ? data.gender === '0' ? '女' : '男' : null }}</p>
            </el-col>
            <el-col :span="6">
              <p>预约人手机号：{{ data.phoneNo }}</p>
            </el-col>
            <el-col :span="6">
              <p>预约人身份证号：{{ data.idCardNo }}</p>
            </el-col>
          </el-row>
        </div>

        <ul class="orderDetails-info-make-time">
          <li class="orderDetails-info-make-time-li" v-for="(item,index) in data.appointments" :key="index" >
            <p class="orderDetails-info-make-time-li-title">{{  $store.state.category.filter(items => { return items.dictKey == item.productCategory })[0].dictValue }}</p>
            <ul class="orderDetails-info-make-time-li-content">
              <li>预约日期：{{ item.appointmentDate ? item.appointmentDate : item.appointmentStartDate ? item.appointmentStartDate + "~" + item.appointmentEndDate : null }}</li>
              <li>预约时段：{{ item.appointmentTime }}</li>
              <li>预约地点：{{ item.hospitalName }}</li>
            </ul>
          </li>
        </ul>

      </div>

    </div>

<!--    服务动态-->
    <div class="orderDetails-info" v-if="data.appointments && data.appointments.length != 0" >

      <div class="orderDetails-info-title">
        服务动态
      </div>

      <ul class="orderDetails-info-step">
        <li class="orderDetails-info-step-li" v-for="(item,index) in data.appointments" :key="index">
          <p class="orderDetails-info-step-li-title">{{ $store.state.category.filter(items => { return items.dictKey == item.productCategory })[0].dictValue }}</p>
          <div class="orderDetails-info-step-li-content">
            <el-steps direction="vertical" :active="item.serviceCount">
              <el-step title="预约提交时间" :description="item.successAppointmentTime"></el-step>
              <el-step :title=" '客服确认时间 客服：' + ( item.confirmedServiceName ? item.confirmedServiceName : '' ) " :description="item.confirmedTime"></el-step>
              <el-step :title=" '开始检查时间 运营：' + ( item.operateName ? item.operateName : '' ) " :description="item.startPhysicalTime"></el-step>
              <el-step title="检查完成时间" :description="item.endPhysicalTime"></el-step>
              <el-step title="诊断中时间" :description="item.inDiagnoseTime"></el-step>
              <el-step title="诊断完成时间" :description="item.diagnoseTime"></el-step>
            </el-steps>
          </div>
        </li>
      </ul>

    </div>

  </div>
</template>

<script>
export default {
  name: "OrderDeta",
  data() {
    return {
      data: {},
    }
  },
  created() {
    this.getDetails(this.$route.query.id);

    console.log(this.$store.state.category)

  },
  methods: {
    getDetails(y) {
      this.$get("orderManage/detail",y,true)
        .then(res => {

          if(res) {
            console.log(res)
            this.data= res.data;
          }

        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../style/Order/OrderDetails";
</style>
